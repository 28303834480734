// ErrorMessage.js
import React from 'react';

const ErrorMessage = ({ className, message }) => {
    return (
        <div className={`error-box ${className}`}>
            {message}
        </div>
    );
};

export default ErrorMessage;