// SuccessMessage.js
import React from 'react';

const SuccessMessage = ({ className, message }) => {
    return (
        <div className={`success-box ${className}`}>
            {message}
        </div>
    );
};

export default SuccessMessage;
