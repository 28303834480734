import React, { useState } from 'react';

function TooltipWrapper({ children, tooltipText }) {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => setShowTooltip(true);
    const handleMouseLeave = () => setShowTooltip(false);

    return (
        <div className="toolTip">
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
            {showTooltip && (
                <div
                    className="inside"
                >
                    {tooltipText}
                </div>
            )}
        </div>
    );
}

export default TooltipWrapper;
