import React, { useState } from 'react';
import "../../App.css"
import axios from "axios";
import TextFieldComp from "../TextFieldComponent";
import ToolTip from "../ToolTip";


function Settings({token, setError, setSuccess, isAdmin}) {
    // rendering logic for middle section where you select a game based off the product
    const [keyFix, setKeyFix] = useState("")
    const [paypalName, setPaypalName] = useState("")
    const [id, setId] = useState(0)

    let lengths = {}

    const handleKeyFixChange = (event) => {
        setKeyFix(event.target.value);
    }
    const handlePaypalNameChange = (event) => {
        setPaypalName(event.target.value);
        console.log(paypalName)
    }



    const setPriceDict = (event) => {

    }

    const handleIDChange = (event) => {
        setId(event.target.value)
    }



    const keyfixRequest = async () => {
        try {
            if (keyFix === "") {
                setError("Keyfix cannot be empty.")
                return
            }
            const encodedText = encodeURIComponent(keyFix);

            const response = await axios.get(`https://desync.mysrv.us/settings/` + "keyfix" + "/" + encodedText + "/" + token + "?nocache=");
            if (response.status === 200) {
                setSuccess(response.data);
                // handle success message
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Login token expired, please login again.");
                    // handle automatic logout
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };

    const paypalNameRequest = async () => {
        try {
            if (paypalName === "") {
                setError("Paypal name cannot be empty.")
                return
            }
            const encodedText = encodeURIComponent(paypalName);

            const response = await axios.get(`https://desync.mysrv.us/settings/` + "paypal" + "/" + encodedText + "/" + token + "?nocache=");
            if (response.status === 200) {
                setSuccess(response.data); // Clear any previous error message
                // handle success message
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Login token expired, please login again.");
                    // add code to auto logout
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };





    return (
        <div className="PageDefaults">
            <div className={"BoxItemFlexBox"}>
                <div className="KeyManage">
                    <div className={"InputKey"}>
                        <h2>User Settings</h2>
                        <div className="BoxItem">
                            <div>
                                <p>Set Key Prefix</p>
                                <TextFieldComp label={"Keyfix"} type={"text"} placeholder={"Less than 8 characters"} onChangeFunc={handleKeyFixChange} inputProps={{ maxLength: 8 }} isMultiline={false} />
                            </div>
                            <div style={{paddingBottom: "2ch"}}>
                                <button onClick={keyfixRequest}>
                                    Set Keyfix
                                </button>
                            </div>
                            <div>
                                <div>
                                    <div className={"toolTipContainer"}>
                                        <p>Set Paypal Name</p>
                                        <ToolTip tooltipText={"If you are paying with paypal, you must set your paypal name here <em>exactly</em> how it is written on your paypal account."} />
                                    </div>

                                    <TextFieldComp type={"text"} placeholder={"Case Sensitive"} onChangeFunc={handlePaypalNameChange} label={"Paypal Name"} />
                                </div>

                                <div>
                                    <button onClick={paypalNameRequest}>
                                        Set Paypal Name
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Settings;