import React, { useState } from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const AppleSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#b59c02',
            },
        },
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#b59c02',
    },
}));

const AppleStyledSwitch = ({isOn, handleChange, onText, offText, label}) => {


    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
            <p style={{position: 'absolute', top: '-20px',left:"0px",  margin: 0}}>{isOn ? onText : offText}</p>

            <FormControlLabel
                control={
                    <AppleSwitch checked={isOn} onChange={handleChange}/>
                }
                label={label}
            />
        </div>
    );
};

export default AppleStyledSwitch;
