import React, { useState, useEffect, useRef } from 'react';
import "../../App.css"
import axios from "axios";
import TextFieldComp from "../TextFieldComponent";
import baseLink from "../data/api-base-link";



// Function to request data from the server


// Function to load data





function UserSettings({userInfo,setUserData, token, setError, setSuccess}) {
    // rendering logic for middle section where you select a game based off the product
    const [seller, setSeller] = useState("")
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false)
    const [searchFieldValue, setSearchFieldValue] = useState("")
    const historyListRef = useRef(null);
    const [userDiscount, setUserDiscount] = useState(null)
    const [userName, setUserName] = useState("")
    const [userPaypal, setUserPaypal] = useState("")
    const [userBalance, setUserBalance] = useState("0")
    const [userKeyfix, setUserKeyfix] = useState("")
    const [activeRow, setActiveRow] = useState(null);
    const [keyActionAmount, setKeyActionAmount] = useState(0)





    useEffect(() => {
        const handleScroll = () => {
            const list = historyListRef.current;
            if (list) {
                const rows = list.querySelectorAll('.row');
                rows.forEach(row => {
                    const rect = row.getBoundingClientRect();
                    const listRect = list.getBoundingClientRect();

                    // Calculate visible height within the list viewport
                    const visibleHeight = Math.min(rect.bottom, listRect.bottom) - Math.max(rect.top, listRect.top);

                    // Ensure the visible height is within valid bounds
                    const clampedVisibleHeight = Math.max(0, Math.min(rect.height, visibleHeight));

                    // Calculate visibility percentage
                    const visibilityPercentage = clampedVisibleHeight / rect.height;

                    // Apply styles based on visibility
                    if (visibilityPercentage > 0 && visibilityPercentage < 1) {
                        row.style.opacity = visibilityPercentage.toString();
                        row.style.transform = `scale(${visibilityPercentage})`;
                        row.style.visibility = 'visible';
                    } else if (visibilityPercentage <= 0) {
                        row.style.opacity = '0';
                        row.style.transform = 'scale(0)';
                        row.style.visibility = 'hidden'; // Hide the row completely
                    } else {
                        row.style.opacity = '1';
                        row.style.transform = 'scale(1)';
                        row.style.visibility = 'visible';
                    }
                });
            }
        };

        const list = historyListRef.current;
        if (list) {
            list.addEventListener('scroll', handleScroll);
            // Call handleScroll initially to set the correct state on load
            handleScroll();
        }

        return () => {
            if (list) {
                list.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    // add useEffect for retrieving userdata here, should only run once

    const handleRowClick = (key) => {
        setActiveRow(key);
    };

    const handlePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    }



    const handleSaveUserSettings = async () => {
        let settingsDict = {}
        if (userDiscount) {
            settingsDict['discount'] = userDiscount
        }
        if (userPaypal) {
            settingsDict['paypal'] = userPaypal
        }
        if (userKeyfix) {
            settingsDict['keyfix'] = userKeyfix
        }
        if (parseInt(keyActionAmount) > 0) {
            settingsDict['action_limit'] = keyActionAmount
        }

        if (parseFloat(userBalance) >= 0) {
            settingsDict['balance'] = userBalance
        }

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/users/edit/` + activeRow + "/" + token, settingsDict);
            if (response.status === 200) {
                setSuccess(response.data)
            }
        }
        catch (error) {
            if (error.response) {
                setError(error.response.data)
            }
        }

    }

    const handleConfirmPopup = () => {
        setConfirmPopupOpen(!confirmPopupOpen)
    }

    const handleSetKeyActionAmount = (event) => {
        let intVal = parseInt(event.target.value)
        if (isNaN(intVal) || intVal < 0) {
            intVal = 0
        }
        setKeyActionAmount(intVal)
    }

    const handleSetUserDiscount = (event) => {
        setUserDiscount(event.target.value)
    }

    const handleSetUserPaypal = (event) => {
        setUserPaypal(event.target.value)
    }

    const handleSetUserBalance = (event) => {
        let floatVal = parseFloat(event.target.value)

        if (isNaN(floatVal) || floatVal < 0) {
            floatVal = 0
        }
        setUserBalance(floatVal)
    }

    const handleSetUserKeyfix = (event) => {
        setUserKeyfix(event.target.value)
    }

    const handleSearch = (event) => {
        setSearchFieldValue(event.target.value)
    }

    const userDeleteCallback = async () => {
        try {
            const response = await axios.get(`https://${baseLink}/settings/admin/users/delete/${activeRow}/${token}`);
            if (response.status === 200) {
                setSuccess(response.data)

            }
        } catch (error) {
            setError(error.response.data)
        }

        handleConfirmPopup()
    }

    return (
        <div className="PageDefaults">
            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-content-large">
                        <span className="close-btn" onClick={handlePopup}>&times;</span>
                        <div className={"hSort"}>
                            <div className="vSort">

                            </div>

                            <div className={"vSort"}>

                            </div>
                        </div>

                    </div>
                </div>
            )}

            {confirmPopupOpen && (
                <div className="popup">
                    <div className="popup-content-large">
                        <span className="close-btn" onClick={handleConfirmPopup}>&times;</span>
                        <div className={"hSort"}>
                            <div className={"vSort"}>
                                <h2>Delete this user?</h2>
                                <button onClick={userDeleteCallback}>Confirm</button>
                            </div>
                        </div>

                    </div>
                </div>
            )}


            <div className={"BoxItemFlexBox"}>
                <div className={"ActionHistory"}>
                    <div className={"SortedActions"}>
                        <h2>Select User</h2>
                        <div className={"BoxItem"}>
                            <div>
                                <p>Search for User</p>
                                <TextFieldComp type={"text"} label={"Search for User"}
                                               placeholder={"UserID or Username"} isMultiline={false}
                                               onChangeFunc={handleSearch} style={{width: "96%"}}/>

                            </div>
                            <div className={"HistoryResults"}>
                                <ul className={"HistoryList"} ref={historyListRef}>
                                    {!Object.keys(userInfo) && (
                                        <li className="row" id={0}>
                                            <div className={"rowText"}>
                                                <div id="title">
                                                    <p>
                                                        You have no users.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    {searchFieldValue ? (
                                        <>
                                            {Object.entries(userInfo).map(([key, val]) => (
                                                <>
                                                    {(key.startsWith(searchFieldValue) || (val && val.dusername && val.dusername.toLowerCase().includes(searchFieldValue.toLowerCase()))) &&  (
                                                        <li key={key} className={`row ${activeRow === key ? 'active' : ''}`}
                                                            id={key} onClick={() => handleRowClick(key)}>
                                                            <div id="title">
                                                                <div className={"rowText"}>
                                                                    <p>
                                                                        <div className={"avatarWrapper"}>
                                                                            {val.avatar && (
                                                                                <img className={"avatar"}
                                                                                     src={val.avatar}
                                                                                     alt={"asd"}/>)

                                                                            }
                                                                            {val.dusername || "N/A"}</div>
                                                                        <em>ID:</em> {key}<br/>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}

                                                </>
                                            ))}

                                        </>
                                    ) : (
                                        <>
                                            {Object.entries(userInfo).map(([key, val]) => (
                                                <li key={key} className={`row ${activeRow === key ? 'active' : ''}`}
                                                    id={key} onClick={() => handleRowClick(key)}>
                                                    <div id="title">
                                                        <div className={"rowText"}>
                                                            <p>
                                                                <div className={"avatarWrapper"}>
                                                                    {val.avatar && (
                                                                        <img className={"avatar"}
                                                                             src={val.avatar}
                                                                             alt={"asd"}/>)

                                                                    }
                                                                    {val.dusername || "Not Set"}</div>
                                                                <em>ID:</em> {key} <br/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </>
                                    )}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="UserInfo">
                    <h2>User Info</h2>
                    <div className="BoxItem" >
                        {activeRow ? (
                            <>
                                <p>Details</p>
                                <div className={"vSort text-select"}>

                                    <p><em>User:</em> {userInfo?.[activeRow]?.dusername}</p>
                                    <p><em>ID:</em> {activeRow}</p>
                                    <p><em> Balance:</em> $ {userInfo?.[activeRow]?.balance ?? "Not Set"}</p>
                                    <p>
                                        <em>Discount:</em> {userInfo?.[activeRow]?.discount ? (`${(parseFloat(userInfo[activeRow].discount) * 100).toString()} %`) : ("Not Set")}
                                    </p>
                                    <p><em>Keyfix:</em> {userInfo?.[activeRow]?.keyfix ?? "Not Set"}</p>
                                    <p><em>Paypal:</em> {userInfo?.[activeRow]?.paypal ?? "Not Set"}</p>
                                </div>
                                <p>Statistics</p>
                                <div className={"vSort"}>
                                    <p><em> Total Volume:</em> $ {userInfo?.[activeRow]?.volume ?? "Not Set"}</p>
                                    <p><em> Keys Sold:</em> {userInfo?.[activeRow]?.keys ?? "Not Set"}</p>
                                </div>
                                <div>
                                    <button onClick={handleConfirmPopup}>
                                        Remove User
                                    </button>
                                </div>
                            </>
                        ) : (
                            <p>Please select a user.</p>
                        )}


                    </div>

                </div>


                <div className="ProductManage">
                    <h2>Edit User Settings</h2>
                    <div className="BoxItem">
                        <div className={"BoxScrollContainer"}>
                            <p>Set Discount</p>
                            <TextFieldComp type={"number"} label={"Discount"} value={userDiscount}
                                           placeholder={userInfo[activeRow] && userInfo[activeRow].discount ? "Current: " + userInfo[activeRow].discount : "Error"}
                                           onChangeFunc={handleSetUserDiscount} inputProps={{min: "0", maxLength: 3}}/>
                                <p>Set Paypal Name</p>
                            <TextFieldComp type={"text"} value={userPaypal} label={"Paypal Name"}
                                           placeholder={userInfo[activeRow] && userInfo[activeRow].paypal ? "Current: " + userInfo[activeRow].paypal : "Not Set"}
                                           onChangeFunc={handleSetUserPaypal}/>
                            <p>Set Keyfix</p>
                            <TextFieldComp type={"text"} label={"Key Prefix"} value={userKeyfix}
                                           placeholder={userInfo[activeRow] && userInfo[activeRow].keyfix ? "Current: " + userInfo[activeRow].keyfix : "Not Set"}
                                           onChangeFunc={handleSetUserKeyfix}/>

                            <p>Set Key Action Limit</p>
                            <TextFieldComp type={"number"} label={"Reset/Freeze Limit"}
                                           value={keyActionAmount.toString()} placeholder={"Integers Only"}
                                           onChangeFunc={handleSetKeyActionAmount}/>

                            <p>Balance</p>
                            <TextFieldComp type={"number"} label={"Amount"} value={userBalance.toString()}
                                           placeholder={"This will SET the balance"}
                                           onChangeFunc={handleSetUserBalance}/>
                            <div className={"hSort"}>

                                <div>
                                    <button onClick={handleSaveUserSettings}>Save Settings</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSettings;