import React, {useEffect, useRef, useState} from 'react';
import "../../App.css"
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import axios from "axios";
import TextFieldComp from "../TextFieldComponent";
import SelectComp from "../SelectComponent";
import CheckboxComp from "../CheckboxComp";
import { IconButton } from '@mui/material'; // Import IconButton from MUI
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import baseLink from "../data/api-base-link";
import myTheme from "../themes/mui-theme";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ToolTip from "../ToolTip";
export const setLocalApiKey = (apiKey) => {
    localStorage.setItem('apiKey', apiKey);
};

export const getLocalApiKey = () => {
    return localStorage.getItem('apiKey');
};



function ProductSettings({token, setGameData, setError, setSuccess, menuItems, apiKeyData}) {
    // rendering logic for middle section where you select a game based off the product
    const historyListRef = useRef(null);
    const [activeRow, setActiveRow] = useState(null);
    const [product, setProduct] = useState("")
    const [pID, setId] = useState("")
    const [amountDict, setAmountDict] = useState({})
    const [keyDict, setKeyDict] = useState({})
    const [keyCount, setKeyCount] = useState({})
    const [isStockedItem, setStockedItem] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [newProductID, setNewProductID] = useState(null)
    const [newProductName, setNewProductName] = useState("")
    const [newProductLengths, setNewProductLengths] = useState({})
    const [newLength, setNewLength] = useState(0)
    const [newPrice, setNewPrice] = useState(0)
    const [pName, setName] = useState("")
    const [status, setStatus] = useState("")
    const [sellerVisibility, setSellerVisibility] = useState(false)
    const [apiKey, setApiKey] = useState(getLocalApiKey())
    const [isApiPopupOpen, setIsApiPopupOpen] = useState(false)
    const [isBundleApiPopupOpen, setIsBundleApiPopupOpen] = useState(false)
    const [apiLinkDict, setApiLinkDict] = useState(false)
    const [isApiKeyPopupOpen, setIsApiKeyPopupOpen] = useState(false)
    const [apiKeySearch, setApiKeySeach] = useState("")
    const [tempApiKey, setTempApiKey] = useState("")
    const [selectedEmoji, setSelectedEmoji] = useState(0);
    const emojis = ['🟢','🟡','🟠','🔵','🔴'];
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [bundleProductOne, setBundleProductOne] = useState("");
    const [bundleProductTwo, setBundleProductTwo] = useState("");
    const [docs, setDocs] = useState("")
    const [loader, setLoader] = useState("")
    const [useCustomPricing, setUseCustomPricing] = useState(false)
    const [featurePopup, setFeaturePopup] = useState(false)
    const [featureDict, setFeatureDict] = useState({})
    const [productImageLinks, setProductImageLinks] = useState("")
    const [productVideoLinks, setProductVideoLinks] = useState("")
    const [newProductIcon, setNewProductIcon] = useState("")
    const [newProductImages, setNewProductImages] = useState("")
    const [newProductVideos, setNewProductVideos] = useState("")
    const [icon, setIcon] = useState("")
    const [productLink, setProductLink] = useState("")

    useEffect(() => {
        const handleScroll = () => {
            const list = historyListRef.current;
            if (list) {
                const rows = list.querySelectorAll('.row');
                rows.forEach(row => {
                    const rect = row.getBoundingClientRect();
                    const listRect = list.getBoundingClientRect();

                    // Calculate visible height within the list viewport
                    const visibleHeight = Math.min(rect.bottom, listRect.bottom) - Math.max(rect.top, listRect.top);

                    // Ensure the visible height is within valid bounds
                    const clampedVisibleHeight = Math.max(0, Math.min(rect.height, visibleHeight));

                    // Calculate visibility percentage
                    const visibilityPercentage = clampedVisibleHeight / rect.height;

                    // Apply styles based on visibility
                    if (visibilityPercentage > 0 && visibilityPercentage < 1) {
                        row.style.opacity = visibilityPercentage.toString();
                        row.style.transform = `scale(${visibilityPercentage})`;
                        row.style.visibility = 'visible';
                    } else if (visibilityPercentage <= 0) {
                        row.style.opacity = '0';
                        row.style.transform = 'scale(0)';
                        row.style.visibility = 'hidden'; // Hide the row completely
                    } else {
                        row.style.opacity = '1';
                        row.style.transform = 'scale(1)';
                        row.style.visibility = 'visible';
                    }
                });
            }
        };

        const list = historyListRef.current;
        if (list) {
            list.addEventListener('scroll', handleScroll);
            // Call handleScroll initially to set the correct state on load
            handleScroll();
        }

        return () => {
            if (list) {
                list.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    // add useEffect for retrieving userdata here, should only run once


    const handleSetCustomPricing = (event) => {
        setUseCustomPricing(event.target.checked)
    }

    const handleLinkChange = (event) => {
        setProductLink(event.target.value)
    }

    const addNewFeature = (feature) => {
        let name = "New Features"
        let count = 1;
        while(true) {
            if (name in featureDict) {
                name = `New Features ${count}`
                count++;
            }

            else{
                break
            }
        }
        setFeatureDict(prevDict => ({
            ...prevDict,
            [name]: ""
        }));

    }

    const handleRowClick = (key) => {
        setActiveRow(key);
        setProductEvent(key)
        setUseCustomPricing(menuItems[key].custompricing)

    };

    const handleApiKeySearch = (event) => {
        setApiKeySeach(event.target.value)
    }


    const handleAddProduct = () => {
        if (isPopupOpen && menuItems && product && menuItems[product] && menuItems[product].visibility) {
            setSellerVisibility(menuItems[product].visibility)
        }

        else {
            setSellerVisibility(false)
        }
        setFeatureDict({})
        setIsPopupOpen(!isPopupOpen);
    };

    const handleRemoveApiKey = () => {
        setApiKey("")
        setLocalApiKey("")
        setApiLinkDict(false)
    }

    const handleCreateKey = async (type) => {
        try {
            const response = await axios.get(`https://${baseLink}/api/keys/${type}/${token}`);

            if (response.status === 200) {
                if (response.data) {
                    setApiLinkDict(response.data) // change this to add new key to api link dict
                }
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }
    const handleManageApiKeys = () => {
        setIsApiKeyPopupOpen(!isApiKeyPopupOpen)
    }

    const handleToggleApiPopup = () => {
        setIsApiPopupOpen(!isApiPopupOpen)
        if (apiKey && !(apiKey === "")) {
            handleAPILinks()
        }
        setApiLinkDict(false)
    }

    const handleToggleBundleApiPopup = () => {
        setIsBundleApiPopupOpen(!isApiPopupOpen)
        if (apiKey && !(apiKey === "")) {
            handleAPILinks()
        }
        setApiLinkDict(false)
    }

    const handleAPILinks = async () => {
        setApiKey(tempApiKey)
        setLocalApiKey(apiKey); // set api key to local storage
        try {
            const response = await axios.get(`https://${baseLink}/api/links/${product}/${apiKey}/${token}`);

            if (response.status === 200) {
                if (response.data) {
                    setApiLinkDict(response.data)
                }
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data)
            } else {
                setError("Application Error")
            }
        }
    }
    const handleSetBundleProductOne = (event) => {
        setBundleProductOne(event.target.value)
    }
    const handleSetBundleProductTwo = (event) => {
        setBundleProductTwo(event.target.value)
    }

    const handleBundleAPILinks = async () => {
        setApiKey(tempApiKey)
        setLocalApiKey(apiKey); // set api key to local storage

        if (apiKey === "") {
            setError("Must enter an API key")
            return
        }

        if (bundleProductOne === "" || bundleProductTwo === "") {
            setError("Must select both products")
            return
        }
        try {
            const response = await axios.get(`https://${baseLink}/api/links/bundle/${bundleProductOne}/${bundleProductTwo}/${apiKey}/${token}`);

            if (response.status === 200) {
                if (response.data) {
                    setApiLinkDict(response.data)
                }
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data)
            } else {
                setError("Application Error")
            }
        }
    }

    const initKeyData = () => {
        let newKeyDict = {}
        for (const product in menuItems) {
            if (menuItems[product].stocked) {
                initKeyCount(product, menuItems[product].stock)
                newKeyDict[product] = menuItems[product].stock
            }
        }
        setKeyDict(newKeyDict)
    }

    const initKeyCount = (product, newKeyDict) => {
        const newKeyCount = {};
        for (const key in newKeyDict) {
            const nonEmptyLinesCount = newKeyDict[key].split("\n").filter(line => line.trim() !== "").length;
            newKeyCount[key] = nonEmptyLinesCount;
        }

        setKeyCount(prevKeyCount => ({
            ...prevKeyCount,
            [product]: newKeyCount
        }));
    };

    const updateKeyCount = (product, newKeyDict) => {
        const newKeyCount = {};
        for (const key in newKeyDict) {
            const nonEmptyLinesCount = newKeyDict[key].split("\n").filter(line => line.trim() !== "").length;
            newKeyCount[key] = nonEmptyLinesCount;
        }

        setKeyCount(prevKeyCount => ({
            ...prevKeyCount,
            [product]: newKeyCount
        }));
    };

    const handleSetKeyDict = (length, value) => {
        try {
            // Create a new amountDict with the updated value
           const newKeyDict = {
                ...keyDict,
                [product]: {
                    ...keyDict[product], // Preserve existing keys and values for the product
                    [length]: value.target.value // Use the length variable as the key
                }
            };

            // Update the state with the new amountDict
            setKeyDict(newKeyDict);
            updateKeyCount(product,newKeyDict[product]);

        }
        catch(error) {
            setError(error)
        }
    }



    const handleRestock = async () => {
        const stockDict= {"stock":keyDict[product]}
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/${product}/${token}`, stockDict);

            if (response.status === 200) {
                if (response.data) {
                    setSuccess(response.data.message)
                    setGameData(prevData => ({
                        ...prevData,
                        [product]: {
                            ...prevData[product], // Spread the existing product data
                            ...response.data.productData // Overwrite with the new data
                        }
                    }));
                }
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const createNewProduct = async () => {

        if (!newProductName || !newProductID) {
            setError("Must enter a valid Product Name and ID")
            return
        }

        if (!newProductLengths) {
            setError("Must enter valid Product Lengths")
            return
        }



        const productDict = {name: newProductName, stocked: isStockedItem, id:newProductID, lengths: newProductLengths, visibility: sellerVisibility, features: featureDict, images: newProductImages, videos: newProductVideos, logo:newProductIcon};
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/new/${token}`, productDict);

            if (response.status === 200) {
                if (response.data) {
                    setSuccess(response.data.message)
                    setGameData(prevData => ({
                        ...prevData,
                        [newProductName]: response.data.productData
                    }));
                    const tempStockDict = {}
                    for (let length of Object.keys(newProductLengths)) {
                        tempStockDict[`${length} Day`] = ""
                    }
                    // update key dict manually because im lazy to make a general function
                    for (let length of Object.keys(tempStockDict)) {
                        try {
                            // Create a new amountDict with the updated value
                            const newKeyDict = {
                                ...keyDict,
                                [newProductName]: {
                                    ...keyDict[newProductName], // Preserve existing keys and values for the product
                                    [length]: "" // Use the length variable as the key
                                }
                            };

                            // Update the state with the new amountDict
                            setKeyDict(newKeyDict);
                            updateKeyCount(newProductName,newKeyDict[newProductName]);

                        }
                        catch(error) {
                            setError(error)
                        }
                    }




                    setIsPopupOpen(false)
                }
                // add new product info to dict here
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const handleDeleteProduct = async () => {
        try {
            const response = await axios.get(`https://${baseLink}/settings/admin/products/delete/` + product + "/" + token);
            if (response.status === 200) {
                setSuccess(response.data)
                try {
                    setGameData(prevData => {
                        const newData = {...prevData};
                        delete newData[product]; // Remove the key from the dictionary
                        return newData;
                    });
                }
                catch(newError) {

                }
                setActiveRow("")
                setProduct("")

            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setProductPricing = async () => {

        const settingsDict = {}
        if (useCustomPricing) {
            settingsDict['custompricing'] = amountDict
        }

        else {
            settingsDict['prices'] = amountDict
        }

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);
            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));


            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }


    }

    const setMediainfo = async() => {
        let settingsDict = {}

        if (!(icon === "")) {
            settingsDict['logo'] = icon
        }

        if (!(productImageLinks === "")) {
            settingsDict['images'] = productImageLinks
        }
        if (!(productVideoLinks === "")) {
            settingsDict['videos'] = productVideoLinks
        }

        console.log(settingsDict)
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setStatusInfo = async () => {
        let settingsDict = {}

        if (!(status === "")) {
            settingsDict['status'] = status
        }
        const tempDict = {0: "green", 1: "yellow", 2:"orange", 3:"blue", 4:"red"}
        settingsDict['statuscolor'] = tempDict[selectedEmoji]

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setProductLinks = async() => {
        let settingsDict = {}
        if (!(loader === "")) {
            let wUrl = loader;
            if (!(wUrl.startsWith("http"))) {
                wUrl = "https://"+ wUrl
            }
            settingsDict["loader"] = wUrl
        }

        if (!(productLink === "")) {
            settingsDict['link'] = productLink
        }

        if (!(docs === "")) {
            let wUrl = docs;
            if (!(wUrl.startsWith("http"))) {
                wUrl = "https://"+ wUrl
            }
            settingsDict["instructions"] = wUrl
        }


        if (!(sellerVisibility === null)) {
            settingsDict['visibility'] = sellerVisibility
        }

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setProductInfo = async () => {
        let settingsDict = {}


        if (!(loader === "")) {
            let wUrl = loader;
            if (!(wUrl.startsWith("http"))) {
                wUrl = "https://"+ wUrl
            }
            settingsDict["loader"] = wUrl
        }

        if (!(productLink === "")) {
            settingsDict['link'] = productLink
        }

        if (!(docs === "")) {
            let wUrl = docs;
            if (!(wUrl.startsWith("http"))) {
                wUrl = "https://"+ wUrl
            }
            settingsDict["instructions"] = wUrl
        }

        if (!(pID === "")) {
            settingsDict["id"] = pID
        }
        if (!(pName === "")) {
            settingsDict['name'] = pName
        }

        if (!(status === "")) {
            settingsDict['status'] = status
        }

        if (!(sellerVisibility === null)) {
            settingsDict['visibility'] = sellerVisibility
        }

        const tempDict = {0: "green", 1: "yellow", 2:"orange", 3:"blue", 4:"red"}
        settingsDict['statuscolor'] = tempDict[selectedEmoji]

        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const handleDeleteApiKey = () => {
        if (!activeRow) {
            setError("Please select a key to delete")
        }


    }
    const clearNewProductDict = (event) => {
        setNewProductLengths({})
    }
    const handleNewLengthChange = (event) => {
        let val = event.target.value;

        // Remove leading zeros
        val = val.replace(/^0+/, '');

        // If the resulting value is empty, set it to "0"
        if (val === '') {
            val = '0';
        }

        // Convert to number
        const numberVal = parseInt(val, 10);

        // Check if the parsed value is NaN or negative
        if (isNaN(numberVal) || numberVal < 0) {
            setNewLength(0);
        } else {
            setNewLength(numberVal);
        }
    }

    const handleNewPriceChange = (event) => {
        let val = event.target.value.replace(/^0+(?!\.|$)/, '');
        val = val.replace(/^0+(?!\.|$)/, '');
        let FloatValue = parseFloat(val);

        // Check if the parsed value is NaN or negative
        if (isNaN(FloatValue) || FloatValue < 0) {
            // If NaN or negative, set the value to 0
            FloatValue = 0.0;
        }
        setNewPrice(FloatValue)

    }


    const handleAddNewPriceLengthPair = () => {

        try {// Create a new amountDict with the updated value
            const newProductPriceDict = {
                ...newProductLengths,
                [newLength]: newPrice
            };

            // Update the state with the new amountDict
            setNewProductLengths(newProductPriceDict);
            setSuccess("Added length")

        }
        catch(error) {
            setError(error)
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setSuccess('Copied to clipboard');
                // You can add a success message or other feedback here if needed
            })
            .catch((err) => {
                setError('Failed to copy to clipboard');
            });
    };


    const handleApiKey = (event) => {
        setApiKey(event.target.value)
    }
    const handleNewProductIcon = (event) => {
        setNewProductIcon(event.target.value)
    }
    const handleNewProductImages = (event) => {
        setNewProductImages(event.target.value)
    }

    const handleNewProductVideos = (event) => {
        setNewProductVideos(event.target.value)
    }

    const handleNewProductName = (event) => {
        setNewProductName(event.target.value)
    }

    const handleNewProductID = (event) => {
        setNewProductID(event.target.value)
    }

    const toggleResellerVisibility = (event) => {
        setSellerVisibility(event.target.checked)
    }
    const toggleStockedItem = (event) => {
        setStockedItem(event.target.checked)
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value)
    }

    const handleFeatureNameChange = (event, index) => {
        let newName = event.target.value;

        if (newName in featureDict) {
            newName = newName+"-1"
        }

        setFeatureDict(prevDict => {
            const entries = Object.entries(prevDict);
            const updatedEntry = [newName, entries[index][1]]; // Replace name with new name, keep features unchanged

            return Object.fromEntries([
                ...entries.slice(0, index),
                updatedEntry,
                ...entries.slice(index + 1)
            ]);
        });
    };

    const setPriceDict = (length, value) => {
        let formattedValue = value.replace(/^0+/, '');
        if (formattedValue === '') {
            formattedValue = "0";
        }
        if (formattedValue.startsWith("0") && formattedValue.length > 1) {
            formattedValue = formattedValue.substring(1, formattedValue.length)
        } else {
            // Convert the value to a float
            formattedValue = parseFloat(formattedValue);
            if (isNaN(formattedValue)) {
                // Handle cases where parseFloat fails (e.g., input is non-numeric)
                formattedValue = '';
            }
        }

        // Create a new amountDict with the updated value
        const newAmountDict = {
            ...amountDict,
            [length]: formattedValue
        };

        // Update the state with the new amountDict
        setAmountDict(newAmountDict);

    };


    const setProductEvent = (event) => {
        setProduct(event)
        // set status
        setStatus(menuItems[event].status)
        setSelectedEmoji(parseInt(menuItems[event].statuscolor))
        // testest
        setAmountDict(menuItems[event].lengths)
        // set visibility of product for resellers
        setSellerVisibility(menuItems[event].visibility)


    }

    const handleSetProductImageLinks = (event) => {
        setProductImageLinks(event.target.value)
    }
    const handleSetProductVideoLinks = (event) => {
        setProductVideoLinks(event.target.value)
    }

    const handleSearch = (event) => {
        setSearchFieldValue(event.target.value)
    }

    useEffect(() => {
        // This function will run before anything else loads
        initKeyData()
    }, []);


    const handleSetFeatures = (event, category) => {
        setFeatureDict(prevDict => ({
            ...prevDict,
            [category]: event.target.value
        }));
    }

    const handleLoaderChange = (event) => {
        setLoader(event.target.value)
    }

    const handleDocsChange = (event) => {
        setDocs(event.target.value)
    }

    const handleIconChange = (event) => {
        setIcon(event.target.value)
    }

    const handleFeaturePopup = () =>{
        let newFeatureDict = {}
        for (let [category, featureList] of Object.entries(menuItems[product].features)) {
            newFeatureDict[category] = featureList || "";
        }
        setFeatureDict(newFeatureDict)
        setFeaturePopup(!featurePopup)
    }

    const handleDeleteFeature = (name) => {
        setFeatureDict(prevDict => {
            const updatedDict = { ...prevDict };
            delete updatedDict[name]; // Remove the feature by name
            return updatedDict;
        });
    };

    const handleSaveFeatures = async () => {
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, {"features":featureDict});

            if (response.status === 200) {
                setSuccess(response.data.message)
                setGameData(prevData => ({
                    ...prevData,
                    [product]: {
                        ...prevData[product], // Spread the existing product data
                        ...response.data.productData // Overwrite with the new data
                    }
                }));

            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }




    return (
        <div className="PageDefaults">
            <div className={"BoxItemFlexBox"}>
                <div className={"ProductManage"}>
                    <h2>Select Product</h2>
                    <div>
                        <div className={"BoxItem"}>
                            <div className={"ProductSettingsFlexbox"}>
                                <div className={"PricingSettings"}>
                                    <div>
                                        <div>


                                            <TextFieldComp type={"text"} label={"Search for Product"}
                                                           placeholder={"Product Name"} isMultiline={false}
                                                           onChangeFunc={handleSearch} style={{width: "95%", marginTop: "2ch"}}/>


                                        </div>
                                        <div className={"HistoryResults"}>
                                            <ul className={"HistoryList"} ref={historyListRef}>
                                                {!Object.keys(menuItems) && (
                                                    <li className="row" id={0}>
                                                        <div className={"rowText"}>
                                                            <div id="title">
                                                                <p>
                                                                    You have no products.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                                {searchFieldValue ? (
                                                    <>
                                                        {Object.entries(menuItems).map(([key, val]) => (
                                                            <>
                                                                {(key.toLowerCase().startsWith(searchFieldValue.toLowerCase()) || key.toLowerCase().includes(searchFieldValue.toLowerCase())) && (
                                                                    <li key={key}
                                                                        className={`row ${activeRow === key ? 'active' : ''}`}
                                                                        id={key} onClick={() => handleRowClick(key)}>
                                                                        <div id="title">
                                                                            <div className={"rowText"}>
                                                                                <p>
                                                                                    <em>{key}</em> <br/>
                                                                                    <em>{emojis[val.statuscolor]}</em> {val.status}
                                                                                    <br/><br/>
                                                                                    {keyCount[key] ? (Object.entries(keyCount[key]).map(([length, count], index) => (

                                                                                        <div key={length}>
                                                                                            <em>{length}: </em>{count}<br/>
                                                                                        </div>
                                                                                    ))) : (
                                                                                        <p>No stock available.</p>
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )}

                                                            </>
                                                        ))}

                                                    </>
                                                ) : (
                                                    <>
                                                        {Object.entries(menuItems).map(([key, val]) => (
                                                            <li key={key}
                                                                className={`row ${activeRow === key ? 'active' : ''}`}
                                                                id={key} onClick={() => handleRowClick(key)}>
                                                                <div id="title">
                                                                    <div className={"rowText"}>
                                                                        <p>
                                                                            <em>{key}</em> <br/>
                                                                            <em>{emojis[val.statuscolor]}</em> {val.status}
                                                                            <br/><br/>
                                                                            {keyCount[key] ? (Object.entries(keyCount[key]).map(([length, count], index) => (

                                                                                <div key={length}>
                                                                                    <em>{length}: </em>{count}<br/>
                                                                                </div>
                                                                            ))) : (
                                                                                <p>No stock available.</p>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </>
                                                )}

                                            </ul>
                                            <button onClick={handleAddProduct}
                                                    style={{marginTop: "2ch", marginBottom: "2ch"}}>
                                                New Product
                                            </button>
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>
                        {isPopupOpen && (
                            <div className="popup">
                            <div className="popup-content-large">
                                    <span className="close-btn" onClick={handleAddProduct}>&times;</span>
                                    <div className={"hSort"}>
                                        <div className="vSort">
                                            <p>Product Name</p>
                                            <TextFieldComp isMultiline={false} type={"text"} label={"Enter Name"}
                                                           placeholder={"Case Sensitive"}
                                                           onChangeFunc={handleNewProductName}/>
                                            <p>Product ID</p>
                                            <TextFieldComp isMultiline={false} type={"text"} label={"Enter ID"}
                                                           placeholder={"Integers only for venom products"}
                                                           onChangeFunc={handleNewProductID}/>
                                            <CheckboxComp onChangeFunc={toggleStockedItem} label={"Stocked Item"}/>
                                            <CheckboxComp onChangeFunc={toggleResellerVisibility}
                                                          label={"Public For Resellers"}/>
                                            <p>Product Icon</p>
                                            <TextFieldComp isMultiline={true} type={"text"} label={"Image Links"}
                                                           placeholder={"https://..."}
                                                           onChangeFunc={handleNewProductIcon}/>
                                            <p>Product Images</p>
                                            <TextFieldComp isMultiline={true} type={"text"} label={"Image Links"}
                                                           placeholder={"https://..."}
                                                           onChangeFunc={handleNewProductImages}/>
                                            <p>Product Videos</p>
                                            <TextFieldComp isMultiline={true} type={"text"} label={"Video Links"}
                                                           placeholder={"https://..."}
                                                           onChangeFunc={handleNewProductVideos}/>

                                        </div>

                                        <div className={"vSort"}>
                                            <div>
                                                <p>Enter Pricing Details</p>
                                                <TextFieldComp type={"number"} label={"Amount of Days"}
                                                               placeholder={"Integers Only"}
                                                               onChangeFunc={handleNewLengthChange}
                                                               value={newLength.toString()}/>
                                                <TextFieldComp type={"number"} label={"Price"} placeholder={""}
                                                               onChangeFunc={handleNewPriceChange}
                                                               value={newPrice.toString()}/>
                                                <button onClick={handleAddNewPriceLengthPair}>Add New Length</button>
                                                <button style={{marginLeft: "1ch"}}
                                                        onClick={clearNewProductDict}>Clear
                                                </button>
                                                <p>Add the public prices here.<br/>
                                                    Custom prices for sellers can be<br/> added
                                                    after product is created.</p>
                                                <ul className={"iconlist"}></ul>
                                                {newProductLengths && Object.keys(newProductLengths).map((key, value) => (
                                                    <div className={"iconlistitem"}>
                                                        <p>
                                                            <em>{key} Day{!(key === "1") && ("s")}:</em> $ {newProductLengths[key]}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                            <div className="vSort">
                                                <div className={"addIconSort"}>
                                                    <p>Enter Features </p>
                                                        <AddIcon onClick={addNewFeature} className={"icon"}/>
                                                </div>
                                                <div className={"BoxScrollContainer"}>
                                                {Object.entries(featureDict).map(([name, features], index) => (
                                                    <div className={"vSort"}>
                                                        <div className={"hSort"}>
                                                            <ThemeProvider theme={myTheme}>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': {
                                                                            m: 1,
                                                                            width: '22ch',
                                                                            color: "white",
                                                                            backgroundColor: myTheme.palette.background.main,
                                                                        },
                                                                        '& input': {
                                                                            color: 'white', // Set text color to white
                                                                        },
                                                                        '& .MuiInputBase-inputMultiline': {
                                                                            color: 'white', // Set text color to white for multiline input
                                                                        },
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                            },
                                                                        }, maxHeight: '150px',
                                                                        overflow: 'auto'

                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off">
                                                                    <TextField variant={"outlined"} color={"primary"}
                                                                               value={name}
                                                                               label={"Set Category Name"}
                                                                               placeholder={"Category Name..."}
                                                                               onChange={(event) => handleFeatureNameChange(event, index)}
                                                                               focused/>

                                                                </Box>
                                                            </ThemeProvider>
                                                            <div className={"addIconSort"}>
                                                                <CloseIcon onClick={() => handleDeleteFeature(name)}
                                                                           className={"icon"}/>
                                                            </div>
                                                        </div>
                                                            <ThemeProvider theme={myTheme}>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': {
                                                                            m: 1,
                                                                            width: '25ch',
                                                                            color: "white",
                                                                            backgroundColor: myTheme.palette.background.main,
                                                                        },
                                                                        '& input': {
                                                                            color: 'white', // Set text color to white
                                                                        },
                                                                        '& .MuiInputBase-inputMultiline': {
                                                                            color: 'white', // Set text color to white for multiline input
                                                                        },
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                            },
                                                                        }, maxHeight: '150px',
                                                                        overflow: 'auto'

                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off">

                                                                    <TextField variant={"outlined"} color={"primary"}
                                                                               value={features} multiline
                                                                               label={"Features"}
                                                                               placeholder={"Enter Features"}
                                                                               onChange={(event) => handleSetFeatures(event, name)}
                                                                               focused/>


                                                                </Box>
                                                            </ThemeProvider>

                                                        </div>
                                                        ))}
                                                </div>

                                                    </div>
                                                    </div>
                                                    </div>
                                                    <button onClick={createNewProduct}>
                                    Save Product
                                </button>
                            </div>

                        )}

                    </div>
                </div>


                    {!(product === "") && (
                        <div className={"ProductManage"}>
                            <h2>Product Details</h2>
                            <div className={"BoxItem"}>
                                <div className={"BoxScrollContainer"}>
                                    <div className={"ProductSettingsFlexbox"}>
                                        <div className={"OtherSettings"}>
                                            {/*
                                    <p>Change Product Name</p>
                                    <TextFieldComp label={"Enter Product Name"} placeholder={"Case Sensitive"}
                                                   type={"text"}
                                                   onChangeFunc={handleNameChange}/>

                                    <p>Change Product ID</p>
                                    {menuItems && menuItems[product] && menuItems[product].id ? (
                                        <p><em>ID:</em> {menuItems[product].id}</p>
                                    ) : (
                                        <p><em>Status not available </em></p>
                                    )}
                                    <TextFieldComp label={"Enter Product ID"} placeholder={"ID"} value={pID}
                                                   type={"number"}
                                                   onChangeFunc={handleIDChange}/>
                                    */}
                                            <p>Change Product Status</p>
                                            {menuItems && menuItems[product] && menuItems[product].status ? (
                                                <p><em>Status:</em> {menuItems[product].status}</p>
                                            ) : (
                                                <p><em>Status not available </em></p>
                                            )}

                                            <TextFieldComp label={"Enter Status"} value={status} placeholder={"Status"}
                                                           type={"text"}
                                                           onChangeFunc={handleStatusChange}/>

                                            <div>
                                                <p>Select Status Color</p>
                                                <div className={"emoji-container"}>
                                                    {emojis.map((emoji, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => setSelectedEmoji(index)}
                                                            className={`emoji ${selectedEmoji === index ? 'selected' : ''}`}
                                                        >
                                                            {emoji}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div>
                                                <button onClick={setStatusInfo}>
                                                    Save Status
                                                </button>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div>
                                            <button onClick={handleToggleApiPopup} style={{marginTop: 0}}>
                                                Get API Links
                                            </button>
                                        </div>
                                        <div>
                                            <button onClick={handleToggleBundleApiPopup}>
                                                Get Bundle API Links
                                            </button>
                                        </div>
                                        <hr/>
                                        <p>Set Loader Link</p>
                                        {menuItems[product].loader && (
                                            <a style={{color: "white"}} href={menuItems[product].loader}>Current
                                                Loader</a>
                                        )}

                                        <TextFieldComp label={"Enter Link"} value={loader}
                                                       placeholder={"https://...."}
                                                       type={"text"}
                                                       onChangeFunc={handleLoaderChange}/>

                                        <p>Set Product Instructions Link</p>
                                        {menuItems[product].instructions && (
                                            <a style={{color: "white"}} href={menuItems[product].instructions}>Current
                                                Instructions</a>
                                        )}
                                        <TextFieldComp label={"Enter Link"} value={docs}
                                                       placeholder={"https://...."}
                                                       type={"text"}
                                                       onChangeFunc={handleDocsChange}/>

                                        <p>Set Product Website Link</p>
                                        {menuItems[product].link && (
                                            <a style={{color: "white"}} href={menuItems[product].link}>Current
                                                Website Link</a>
                                        )}
                                        <TextFieldComp label={"Enter Link"} value={productLink}
                                                       placeholder={"https://...."}
                                                       type={"text"}
                                                       onChangeFunc={handleLinkChange}/>


                                        {featurePopup && (
                                            <div className="popup">
                                                <div className="popup-content-large">
                                                        <span className="close-btn"
                                                              onClick={handleFeaturePopup}>&times;</span>
                                                    <div className={"hSort"}>
                                                        <div className={"BoxScrollContainer"}>
                                                            <div className="vSort">

                                                                <div className={"addIconSort"}>

                                                                    <p>Enter Features </p>
                                                                    <AddIcon onClick={addNewFeature}
                                                                             className={"icon"}/>
                                                                </div>

                                                                {Object.entries(featureDict).map(([name, features], index) => (
                                                                    <div className={"vSort"} style={{
                                                                        marginTop: "2ch",
                                                                        marginBottom: "2ch"
                                                                    }}>
                                                                        <div className={"hSort"}>
                                                                            <ThemeProvider theme={myTheme}>
                                                                                <Box
                                                                                    component="form"
                                                                                    sx={{
                                                                                        '& > :not(style)': {
                                                                                            m: 1,
                                                                                            width: '22ch',
                                                                                            color: "white",
                                                                                            backgroundColor: myTheme.palette.background.main,
                                                                                        },
                                                                                        '& input': {
                                                                                            color: 'white', // Set text color to white
                                                                                        },
                                                                                        '& .MuiInputBase-inputMultiline': {
                                                                                            color: 'white', // Set text color to white for multiline input
                                                                                        },
                                                                                        '& .MuiOutlinedInput-root': {
                                                                                            '& fieldset': {
                                                                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                                            },
                                                                                            '&:hover fieldset': {
                                                                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                                            },
                                                                                            '&.Mui-focused fieldset': {
                                                                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                                            },
                                                                                        },
                                                                                        maxHeight: '150px',
                                                                                        overflow: 'auto'

                                                                                    }}
                                                                                    noValidate
                                                                                    autoComplete="off">
                                                                                    <TextField
                                                                                        variant={"outlined"}
                                                                                        color={"primary"}
                                                                                        value={name}
                                                                                        label={"Set Category Name"}
                                                                                        placeholder={"Category Name..."}
                                                                                        onChange={(event) => handleFeatureNameChange(event, index)}
                                                                                        focused/>

                                                                                </Box>
                                                                            </ThemeProvider>
                                                                            <div className={"addIconSort"}>
                                                                                <CloseIcon
                                                                                    onClick={() => handleDeleteFeature(name)}
                                                                                    className={"icon"}/>
                                                                            </div>
                                                                        </div>
                                                                        <ThemeProvider theme={myTheme}>
                                                                            <Box
                                                                                component="form"
                                                                                sx={{
                                                                                    '& > :not(style)': {
                                                                                        m: 1,
                                                                                        width: '25ch',
                                                                                        color: "white",
                                                                                        backgroundColor: myTheme.palette.background.main,
                                                                                    },
                                                                                    '& input': {
                                                                                        color: 'white', // Set text color to white
                                                                                    },
                                                                                    '& .MuiInputBase-inputMultiline': {
                                                                                        color: 'white', // Set text color to white for multiline input
                                                                                    },
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                                        },
                                                                                    },
                                                                                    maxHeight: '150px',
                                                                                    overflow: 'auto'

                                                                                }}
                                                                                noValidate
                                                                                autoComplete="off">

                                                                                <TextField variant={"outlined"}
                                                                                           color={"primary"}
                                                                                           value={features}
                                                                                           multiline
                                                                                           label={"Features"}
                                                                                           placeholder={"Enter Features"}
                                                                                           onChange={(event) => handleSetFeatures(event, name)}
                                                                                           focused/>


                                                                            </Box>
                                                                        </ThemeProvider>

                                                                    </div>
                                                                ))}

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div>
                                                    <button onClick={handleSaveFeatures}>Save Features
                                                    </button>
                                                </div>
                                            </div>
                                        )}


                                        <CheckboxComp onChangeFunc={toggleResellerVisibility}
                                                      label={"Public For Resellers"}
                                                      checked={sellerVisibility}/>
                                        <div>
                                            <button onClick={setProductLinks}>
                                                Save Links
                                            </button>
                                        </div>
                                        <div>
                                            {/*
                                    <button onClick={handleManageApiKeys}>
                                        Manage API Keys
                                    </button>
                                    */}
                                            {isApiKeyPopupOpen && (

                                                <div className="popup">
                                                    <div className="popup-content-large">
                                                        <span className="close-btn"
                                                              onClick={handleManageApiKeys}>&times;</span>
                                                        <div className={"hSort"}>
                                                            <div className={"vSort"}>
                                                                <p>Select API Keys</p>
                                                                <TextFieldComp type={"text"}
                                                                               onChangeFunc={handleApiKeySearch}
                                                                               placeholder={"Key or User"}
                                                                               label={"Search Key or User"}
                                                                               value={apiKeySearch}
                                                                               isMultiline={false}/>
                                                                <div className={"HistoryResults"}>
                                                                    <ul className={"HistoryList"} ref={historyListRef}>

                                                                        {Object.entries(apiKeyData).map(([key, val]) => (
                                                                            <>
                                                                                {!apiKeySearch ? (
                                                                                    <li key={key}
                                                                                        className={`row ${activeRow === key ? 'active' : ''}`}
                                                                                        id={key}
                                                                                        onClick={() => handleRowClick(key)}>
                                                                                        <div id="title">
                                                                                            <div className={"rowText"}>
                                                                                                <em>{val}:</em>
                                                                                                <br/>{key}<br/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                ) : (
                                                                                    <>
                                                                                        {apiKeySearch && apiKeySearch.toLowerCase().startsWith(key.toLowerCase()) || (key.toLowerCase().includes(apiKeySearch.toLowerCase())) || (key) && (
                                                                                            <li key={key}
                                                                                                className={`row ${activeRow === key ? 'active' : ''}`}
                                                                                                id={key}
                                                                                                onClick={() => handleRowClick(key)}>
                                                                                                <div id="title">
                                                                                                    <div
                                                                                                        className={"rowText"}>
                                                                                                        <p>
                                                                                                            <em>{val}:</em> {key}<br/>
                                                                                                            {/* Display other properties similarly */}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className={"vSort"}>
                                                                <p>Add or Delete Keys</p>
                                                                <div>
                                                                    <button onClick={handleCreateKey}>Create Admin Key
                                                                    </button>
                                                                </div>

                                                                <div>
                                                                    <button onClick={handleDeleteApiKey}>Delete Key
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {isBundleApiPopupOpen && (

                                                <div className="popup">
                                                    <div className="popup-content-large">
                                                        <span className="close-btn"
                                                              onClick={() => setIsBundleApiPopupOpen(false)}>&times;</span>
                                                        <div className={"hSort"}>
                                                            {!apiLinkDict ? (
                                                                <div className="vSort">
                                                                    <p>Select First Product</p>
                                                                    <SelectComp menuItems={menuItems}
                                                                                label={"Product One"}
                                                                                onChangeFunc={handleSetBundleProductOne}/>
                                                                    <p>Select First Product</p>
                                                                    <SelectComp menuItems={menuItems}
                                                                                label={"Product Two"}
                                                                                onChangeFunc={handleSetBundleProductTwo}/>
                                                                    <p>Enter API Key</p>
                                                                    <TextFieldComp isMultiline={false} type={"text"}
                                                                                   label={"Api Key"}
                                                                                   placeholder={""}
                                                                                   onChangeFunc={handleApiKey}/>
                                                                    <div>
                                                                        <button onClick={handleBundleAPILinks}>
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="vSort">
                                                                    <h2>API Links
                                                                        for <em>{bundleProductOne}</em> and <em>{bundleProductTwo}</em>
                                                                    </h2>
                                                                    {apiLinkDict && Object.entries(apiLinkDict).map(([key, value]) => (
                                                                        <div key={key} style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}>
                                                                            <p style={{marginRight: '8px'}}>
                                                                                <em>{key}:</em> {value}
                                                                            </p>
                                                                            <IconButton className="copyButton"
                                                                                        onClick={() => handleCopy(value)}
                                                                                        size={"small"}>
                                                                                <ContentCopyIcon sx={{
                                                                                    fontSize: 20,
                                                                                    color: "white",
                                                                                    backgroundColor: "transparent"
                                                                                }}/>
                                                                            </IconButton>
                                                                        </div>
                                                                    ))}
                                                                    <p style={{maxWidth: "500px"}}><em>NOTE:</em> The
                                                                        links generated are only for same lengths,
                                                                        however lengths can be mixed and matched as long
                                                                        as they are available per product.</p>
                                                                    <div>
                                                                        <button onClick={handleRemoveApiKey}>
                                                                            Change API Key
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}


                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <hr/>
                                            <p>Set Product Features</p>
                                            <div>
                                                <button style={{marginTop: "-2ch", marginBottom: "1ch"}}
                                                        onClick={handleFeaturePopup}>Change Features
                                                </button>
                                            </div>
                                            <hr/>
                                            <p>Set Product Logo</p>
                                            <div>
                                                <div className={"toolTipContainer"}>
                                                    <TextFieldComp label={"Enter Icon Link"} value={icon}
                                                                   placeholder={"https://...."}
                                                                   type={"text"}
                                                                   boxWidth={"14ch"}
                                                                   onChangeFunc={handleIconChange}/>
                                                    <div className={"popupImage"}>
                                                        <img
                                                            src={menuItems[product].logo || "favicon.ico"}
                                                            style={{
                                                                width: "100px",
                                                                border: "2px solid #b59c02",
                                                                borderRadius: "10px"
                                                            }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>Set Product Images</p>
                                            <div>
                                                <TextFieldComp label={"Image Links"} placeholder={"https://..."}
                                                               isMultiline={true}
                                                               value={productImageLinks}
                                                               onChangeFunc={handleSetProductImageLinks}/>
                                            </div>

                                            <p>Set Product Videos</p>
                                            <div>
                                                <TextFieldComp label={"Video Links"} placeholder={"https://..."}
                                                               isMultiline={true}
                                                               value={productVideoLinks}
                                                               onChangeFunc={handleSetProductVideoLinks}/>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={setMediainfo}>Save Media
                                                </button>
                                            </div>
                                            <hr/>
                                            {isApiPopupOpen && (

                                                <div className="popup">
                                                    <div className="popup-content-large">
                                                        <span className="close-btn"
                                                              onClick={handleToggleApiPopup}>&times;</span>
                                                        <div className={"hSort"}>
                                                            {!apiLinkDict ? (
                                                                <div className="vSort">
                                                                    <p>Enter API Key</p>
                                                                    <TextFieldComp isMultiline={false} type={"text"}
                                                                                   label={"Api Key"}
                                                                                   placeholder={""}
                                                                                   onChangeFunc={handleApiKey}/>
                                                                    <div>
                                                                        <button onClick={handleAPILinks}>
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="vSort">
                                                                    <h2>API Links for <em>{product}</em></h2>
                                                                    {apiLinkDict && Object.entries(apiLinkDict).map(([key, value]) => (
                                                                        <div key={key} style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}>
                                                                            <p style={{marginRight: '8px'}}>
                                                                                <em>{key}:</em> {value}
                                                                            </p>
                                                                            <IconButton className="copyButton"
                                                                                        onClick={() => handleCopy(value)}
                                                                                        size={"small"}>
                                                                                <ContentCopyIcon sx={{
                                                                                    fontSize: 20,
                                                                                    color: "white",
                                                                                    backgroundColor: "transparent"
                                                                                }}/>
                                                                            </IconButton>
                                                                        </div>
                                                                    ))}
                                                                    <div>
                                                                        <button onClick={handleRemoveApiKey}>
                                                                            Change API Key
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}


                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <p>Product Pricing</p>

                                            {product && menuItems[product] && menuItems[product].lengths &&
                                                Object.keys(menuItems[product].lengths).map((length) => {
                                                    const price = menuItems[product].lengths[length];
                                                    return (
                                                        <>
                                                            <p>
                                                                <em>{length.charAt(0).toUpperCase() + length.slice(1)}</em> {" (Current: $ " + price + ")"}
                                                            </p>
                                                            <ThemeProvider theme={myTheme}>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': {
                                                                            m: 1,
                                                                            width: '25ch',
                                                                            color: "white",
                                                                            backgroundColor: myTheme.palette.background.main,
                                                                        },
                                                                        '& input': {
                                                                            color: 'white', // Set text color to white
                                                                        },
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                            },
                                                                        },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        type="number" // Change type to "text"
                                                                        onChange={(event) => setPriceDict(length, event.target.value.replace(/^0+/, ''))} // Remove leading zeros
                                                                        color="primary"
                                                                        label={"Set Price"}
                                                                        variant="outlined"
                                                                        focused
                                                                        value={amountDict[length] && amountDict[length].toString() || '0'} // Display the value as is from the state
                                                                        inputProps={{min: "0", maxLength: 5}}
                                                                    />
                                                                </Box>
                                                            </ThemeProvider>
                                                        </>

                                                    )
                                                        ;
                                                })}
                                        </div>

                                    </div>
                                    <div>
                                        <button onClick={setProductPricing}>
                                            {useCustomPricing ? ("Save Custom Pricing") : ("Save Pricing")}
                                        </button>
                                    </div>
                                    <hr/>
                                    <div>
                                        <button onClick={handleDeleteProduct} style={{marginTop: 0}}>
                                            Delete Product
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    )}

                <div className="ProductManage">
                    <h2>Stock Items</h2>
                    <div className="BoxItem">
                        {!(product === "") && menuItems[product] && menuItems[product].stocked ? (
                            <div>
                                {menuItems[product].stocked ? (
                                    <>
                                        {product && menuItems[product] && menuItems[product].lengths &&
                                            Object.keys(menuItems[product].lengths).map((length) => {
                                                return (
                                                    <>
                                                        <p>{length.charAt(0).toUpperCase() + length.slice(1)}<br/>


                                                            <em>Count: </em>{keyCount[product][length]}</p>

                                                        <ThemeProvider theme={myTheme}>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': {
                                                                            m: 1,
                                                                            width: '25ch',
                                                                            color: "white",
                                                                            backgroundColor: myTheme.palette.background.main,
                                                                        },
                                                                        '& input': {
                                                                            color: 'white', // Set text color to white
                                                                        },
                                                                        '& .MuiInputBase-inputMultiline': {
                                                                            color: 'white', // Set text color to white for multiline input
                                                                        },
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                            },
                                                                        }, maxHeight: '200px',
                                                                        overflow: 'auto'

                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off">
                                                                    <TextField variant={"outlined"} color={"primary"}
                                                                               value={keyDict[product][length]}
                                                                               multiline label={"Keys"}
                                                                               placeholder={"Seperate with Newline"}
                                                                               onChange={(text) => handleSetKeyDict(length, text)}
                                                                               focused/>
                                                                </Box>
                                                            </ThemeProvider>

                                                        </>
                                                    );
                                                })}
                                            <div>
                                                <button onClick={handleRestock}>
                                                    Save Stock
                                                </button>
                                            </div>
                                        </>

                                    ) : (
                                        <p>Nothing to stock.</p>
                                    )}
                                </div>
                            ) : (<p>This product is not stocked.</p>)}
                        </div>

                    </div>

                </div>
            </div>
            );
            }

            export default ProductSettings;