import React, { useState } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

function TooltipWrapper({ children, tooltipText, boxWidth }) {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => setShowTooltip(true);
    const handleMouseLeave = () => setShowTooltip(false);

    return (
        <div className="toolTip">
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <InfoRoundedIcon />
            </div>
            {showTooltip && (
                <div style={{width: boxWidth || "180px"}}
                    className="inside"
                    dangerouslySetInnerHTML={{ __html: tooltipText }}
                >

                </div>
            )}
        </div>
    );
}

export default TooltipWrapper;
