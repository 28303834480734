import {createTheme} from "@mui/material/styles";

const myTheme = createTheme({
    palette: {
        primary: {
            main: "#b59c02",
        },
        secondary: {
            main: '#FFF',
        },
        background: {
            main: "#121212",
        },
    },
});

export default myTheme;

